import React from "react";
import "./NotFoundData.css";
const NotFoundData = () => {
	return (
		<>
			<h2
				className='d-flex justify-content-center align-items-center'
				style={{
					height: "50vh",
				}}>
				لم يتم العثور على البيانات
			</h2>
		</>
	);
};

export default NotFoundData;
