import React from "react";

import "./WhatWeOffer.css";
import {
	HomeServiceFive,
	HomeServiceFour,
	HomeServiceOne,
	HomeServiceSeven,
	HomeServiceSix,
	HomeServiceThree,
	HomeServiceTow,
} from "../../assets/Img";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const WhatWeOfferData = [
	{
		id: 1,
		icon: HomeServiceOne,
		alt: "خدمة تصميم الشعار",
		title: "خدمة تصميم الشعار",
		description: `اضافة نص المحتوى اضافة نصف المحتوى اضافة نص المحتوى اضافة نص المحتوى`,
	},

	{
		id: 2,
		alt: " التصاميم الاعلانية",
		icon: HomeServiceTow,
		title: " التصاميم الاعلانية",
		description: `اضافة نص المحتوى اضافة نصف المحتوى اضافة نص المحتوى اضافة نص المحتوى`,
	},

	{
		id: 3,
		alt: "خدمة التسويق seo",

		icon: HomeServiceThree,
		title: "خدمة التسويق seo",
		description: `اضافة نص المحتوى اضافة نصف المحتوى اضافة نص المحتوى اضافة نص المحتوى`,
	},

	{
		id: 4,
		alt: "  خدمة التسويق الشامل ",

		icon: HomeServiceFour,
		title: "  خدمة التسويق الشامل ",
		description: `اضافة نص المحتوى اضافة نصف المحتوى اضافة نص المحتوى اضافة نص المحتوى`,
	},
];

const WhatWeOffer = () => {
	const navigate = useNavigate();

	const goUpWindow = () => {
		window.scroll(0, 0);
	};
	return (
		<section className='what-we-offer-section '>
			<section className='container'>
				<section className='what-we-offer-box-content row  align-items-center'>
					{/* text */}
					<div className='col-lg-12 services-head-section mb-4 text-center'>
						<h2>ماذا نقدم لك؟</h2>

						<p>منصة سوق تقدم لك مجموعة من الخدمات لنجاح مشروعك التجاري</p>

						{/*	<button
							onClick={() => {
								navigateToMerchantRegister();
							}}>
							انشئ متجرك الآن
						</button>*/}
					</div>

					{/*boxes*/}

					<div className='col-12 mb-3'>
						<div className='row justify-content-center gap-3 '>
							{WhatWeOfferData?.map((item) => (
								<div
									data-aos-once='true'
									data-aos='fade-up'
									onClick={() => {
										goUpWindow();

										localStorage.setItem(
											"WhatWeOfferInfo",
											JSON.stringify(item)
										);
										navigate(
											`/whatWeOffer/${encodeURIComponent(
												item.title.replace(/[^a-zA-Z0-9\u0621-\u064A]+/g, "-")
											)}`
										);
									}}
									className='what-we-offer-boxs text-center col-xl-4 col-lg-2  col-md-12  '
									key={item.id}>
									<div className='mb-2'>
										{/*	<div>
											<img
												className='what-we-offer-img '
												src={item?.icon}
												alt={item.alt}
											/>
										</div>*/}

										<h3 className='offer-title'>{item?.title}</h3>
									</div>

									<p className='offer-description'>{item?.description}</p>
								</div>
							))}
						</div>
					</div>

					<div className='col-12'>
						<bdi
							className='mt-4 more-service-btn'
							onClick={() => {
								navigate("/services");
							}}>
							المزيد من الخدمات
							<HiOutlineArrowNarrowLeft />
						</bdi>
					</div>
				</section>
			</section>
		</section>
	);
};

export default WhatWeOffer;
