import React from "react";

// Third party
import { toast } from "react-toastify";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

const HeroBanners = ({ homePageData }) => {
	//

	// to disabled right click
	const handleContextMenu = (event) => {
		event.preventDefault(); // Prevent the default right-click behavior

		toast.warning("لا يمكن استخدام خاصية الضغط بالزر الايمن مع هذه القسم", {
			theme: "light",
		});
	};

	const slides = [
		homePageData?.slider1,
		homePageData?.slider2,
		homePageData?.slider3,
	].filter(Boolean); // Filter out undefined or null values

	const isLoopEnabled = slides.length >= 2; // Enable loop only if enough slides

	return (
		<div className='hero' onContextMenu={handleContextMenu}>
			<Swiper
				effect='fade'
				loop={isLoopEnabled} // Dynamically enable loop
				autoplay={{
					delay: 6000,
					disableOnInteraction: false,
				}}
				pagination={{
					dynamicBullets: true,
					clickable: true,
				}}
				modules={[Pagination, EffectFade, Autoplay]}
				className='my_hero_swiper'>
				{slides.map((slide, index) => (
					<SwiperSlide key={index} className='my_hero_swiper_slide'>
						<div
							className='swiper_image'
							style={{
								backgroundImage: `url(${slide})`,
							}}></div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default HeroBanners;
