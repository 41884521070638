import React, { useEffect, useState } from "react";

// Third party
import { useNavigate } from "react-router-dom";
import Media from "react-media";

// Components
import {
	ProductSwiper,
	ReviewSwiper,
	PartnerSwiper,
	NotFoundData,
	WhatWeOffer,
	SouqAtlbhaSwiper,
	FeaturedStores,
	SubscribePackages,
	HeroBanners,
	WhyAtlbha,
	TradeBanners,
	MiddleBanners,
	HomePageSection,
} from "../../index";
import LoadingPage from "../../LoadingPage/LoadingPage";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

// css styles
import "./HomeBox.css";

// Icons
import { MainTitle } from "../../index";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const HomeBox = ({
	homePageData,
	homeLoadingData,
	isFetching,
	setUseDisplayStores,
}) => {
	const navigate = useNavigate();
	const [getMedia, setMedia] = useState("");

	let changeMedia = (e) => {
		e?.small ? setMedia("small") : setMedia("large");
	};

	// ------------------------------------------------------------------

	const goUpWindow = () => {
		window.scroll(0, 0);
	};
	if (homeLoadingData) {
		return <LoadingPage />;
	}

	return (
		<>
			{/* ================= media ========================= */}
			<Media
				queries={{
					small: "(max-width: 599px)",
				}}
				onChange={(e) => {
					changeMedia(e);
				}}
			/>
			{/* ============= Hero Banners ===================== */}
			<HeroBanners homePageData={homePageData} navigate={navigate} />

			{/* =============  special Products ===================== */}
			{homePageData?.products?.length > 0 ? (
				<div className='outstanding-products p-main mb-5 '>
					<div className='container'>
						<MainTitle text={homePageData?.section1} />
						<div className='all'>
							<ProductSwiper productSwiperData={homePageData?.products} />
						</div>
					</div>
				</div>
			) : null}

			{homePageData?.banar1 && homePageData?.banar2 ? (
				<MiddleBanners
					right_banner={homePageData?.banar2}
					left_banner={homePageData?.banar1}
				/>
			) : null}

			{/* ============= Featured Stores ===================== */}
			{homePageData?.stores?.length > 0 ? (
				<FeaturedStores
					isFetching={isFetching}
					homePageData={homePageData}
					setUseDisplayStores={setUseDisplayStores}
				/>
			) : null}

			{/* ============= Our Packages ===================== */}
			<div className='our-package p-main'>
				<div className='container'>
					<MainTitle text={"باقات سوق نيوم"} />
					<SubscribePackages />
				</div>
			</div>

			{homePageData?.banar3 ? (
				<TradeBanners banner={homePageData?.banar3} />
			) : null}

			{/* ============= Our Reviews ===================== */}
			{homePageData?.comment?.length > 0 ? (
				<>
					<MainTitle text={"قالوا عنا"} />
					<ReviewSwiper DataReviewSwiper={homePageData?.comment} />
				</>
			) : null}
		</>
	);
};

export default HomeBox;
